<template>
    <div>
        <p style="text-align: center;">请长按下方二维码关注公众号👇后继续参与活动</p>
        <p>
            <img :src="qr_code" style="width: 60%;margin: 0 20%;" alt="" />
        </p>

    </div>
</template>

<script>
    export default {
        name: "Subscribe",
        data() {
            return {
                url:'',
                qr_code:'',
            }
        },
        mounted() {
            this.url = this.$route.query.url;
            console.log(this.url);
            // 带着当前的链接去请求二维码
            this.post('/api/client/common/subscribe/code', {url: this.url}).then(result => {
                this.qr_code = result.data.url;
            });

        },
        methods:{

        },
    }
</script>

<style scoped>

</style>